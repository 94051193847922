<template>
  <div>
    <div class="main-loader" v-if="isLoading"></div>
    <div class="agents-page" v-else>
      <b-container>
        <b-row>
          <!-- Swiper component if needed -->
        </b-row>

        <!-- Agent Cards -->
        <b-row v-if="paginatedAgents.length">
          <agent-card 
            v-for="agent in paginatedAgents"
            :key="agent.agentId"
            :agent="agent"
            :isSaved="savedAgents.includes(agent.agentId)"
            :showShareButton="false"
            @run-agent="runAgent(agent.agentId)"
            :showUserExecutionCount="true"
          />
        </b-row>

        <!-- No data found message -->
        <div class="no-data mt-5 mb-5" v-else>
          <div class="mx-auto text-center">
            <h2>You have no assistants to create images. Start building assistants on the Build Creative Assistant page. </h2>
          </div>
        </div>

        <!-- Infinite Scroll Trigger -->
        <div ref="loadMoreTrigger" class="infinite-scroll-trigger"></div>
      </b-container>
    </div>
  </div>
</template>

<script>
import agentCard from './agentCard.vue';
import axios from 'axios';

export default {
  name: 'AgentsPage',
  components: {
    agentCard,
  },
  data() {
    return {
      isLoading: false,
      agents: [],
      savedAgents: [],
      agentsPerPage: 1000,
      currentPage: 1,
      observer: null,
    };
  },
  computed: {
    paginatedAgents() {
      return this.agents.slice(0, this.agentsPerPage * this.currentPage);
    },
  },
  async created() {
    await this.fetchAgentsAndSaved();
    this.isLoading = false;
  },
  mounted() {
    this.setupIntersectionObserver();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    async fetchAgentsAndSaved() {
      this.isLoading = true;
      try {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData.userId;

        // Fetch all agents
        const agentsResponse = await axios.get(`${process.env.VUE_APP_BASE_URL}user/savedAgents/${userId}`);
        this.agents = agentsResponse.data.data || [];

        // If savedAgents are part of the response, adjust accordingly
        // this.savedAgents = Array.isArray(agentsResponse.data.savedAgents)
        //   ? agentsResponse.data.savedAgents
        //   : [];

        // Alternatively, extract savedAgents from agents
        this.savedAgents = this.agents.map(agent => agent.agentId);

      } catch (error) {
        console.error('Error fetching agents:', error);
        this.agents = [];
        this.savedAgents = [];
      } finally {
        this.isLoading = false;
      }
    },

    runAgent(agentId) {
      this.$router.push(`/agents/${agentId}`);
    },

    setupIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      };
      this.observer = new IntersectionObserver(this.handleIntersect, options);
      this.observer.observe(this.$refs.loadMoreTrigger);
    },
    handleIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMoreAgents();
      }
    },
    loadMoreAgents() {
      if (this.agents.length > this.paginatedAgents.length) {
        this.currentPage++;
      }
    },
  },
};
</script>

  <style scoped>  
  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: auto;
    box-sizing: border-box;
  }
  
  .swiper-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    margin-top: 15px;
  }
  
  .swiper-container::-webkit-scrollbar {
    display: none;
  }
  
  .tab-button {
    margin: 0 5px;
  }
  
  .infinite-scroll-trigger {
    width: 100%;
    height: 50px;
  }
  /* Swiper and tab styles */
.swiper-slide {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: auto !important;
  box-sizing: border-box;
}

.swiper-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-left: 6px !important;
  margin-right: 6px !important;
  margin-top: 15px !important;
  padding-bottom: 10px !important;
}

.swiper-container::-webkit-scrollbar {
  display: none;
}

.tab-button {
  font-size: 1rem !important;
  margin-bottom: 8px;
  margin-top: 2px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0.48571rem 0.71429rem !important;
  display: inline-flex;
  align-items: center !important;
  justify-content: center !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: hsl(0, 0%, 100%);
  transition: all 0.2s ease-in-out;
}

.tab-button:hover,
.tab-button.is-active {
  background-color: #0069d9;
  color: white;
}

.b-button {
  border-radius: 0.25rem;
}

.ss-width {
  width: auto !important;
}
.agents-page {
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
}
.b-container {
  padding: 0 20px; /* Padding for the container to align content nicely */
}

.no-data {
  text-align: center;
  padding: 20px;
}

  </style>
  